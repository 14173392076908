import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { inputAnatomy } from '@chakra-ui/anatomy'
import { pxToRem } from "../../Utils/mapPxToRem";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

export const baseStyle = definePartsStyle({
	field: {
		boxSizing: "border-box",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		padding: "12px 10px",
		gap: "10px",

		backgroundColor: "transparent",

		width: "100%",
		height: "56px",

		borderWidth: "1px",
		borderStyle: "solid",
		borderColor: "#111111",
		borderRadius: "0",

		flex: "none",
		alignSelf: "stretch",
		flexGrow: 0,

		_placeholder: {
			color: "tints.black.50",
		}
	}
});

const search = definePartsStyle({
	field: {
		height: "56px",
		fontSize: ["md", null, null, pxToRem("64px")],
		borderColor: "primary.white",
		backgroundColor: "secondary.darkGreen",
		borderBottom: "1px solid white",
		focusBorderColor: "blue.500",
		color: "white",
		width: "100%",
		paddingY: pxToRem("52px"),
		borderTop: "none",
		borderLeft: "none",
		borderRight: "none",
		_placeholder: {
			color: "rgba(255, 255, 255, 0.5)",
		}
	}
});

const chatbot = definePartsStyle({
	field: {
		backgroundColor: "secondary.white",
		color: "primary.black",
		rounded: "xl"
	}
});

export const Input = defineMultiStyleConfig({
	baseStyle,
	variants: {
		search,
		chatbot,
	}
});
